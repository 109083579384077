import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";

function AddUser() {
    const [IsPending, setPending] = useState(false);
    let navigate = useNavigate();
    const dispatch = useDispatch();
    let user = useSelector((state) => state.user.user);
    let params = useParams();
    const [allRoel, setAllRoel] = useState([]);
    const [state, setState] = useState({});
    const [preImage, setPreImage] = useState("");
    const [checkboxValues, setCheckboxValues] = useState([]);
    const [permission, setPermission] = useState({});

    useEffect(() => {
        if(user.role.role === "admin") {
            if(params.id){
                getUser();
            }
            getRole();
        } else {
            dispatch({ type: 'LOGOUT', payload: null });
            localStorage.removeItem('session_id');
            navigate("/");
        }
    }, []);

    async function getUser() {
        await axios.get(`${process.env.REACT_APP_BASE_URL}my-user?user=${params.id}`)
            .then(({ data }) => {
                // (newData.permission)
                const newData = { ...data.users };
                delete newData.password;
                if (newData.user_image) {
                    setPreImage(`${process.env.REACT_APP_BASE_URL}uploads/userimages/${newData.user_image}`)
                }
                setInitialValues(newData);
                setCheckboxValues(newData.permission);
                setPermission(JSON.parse(newData.permission));
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const UserPermission = (event) => {
        const { name, checked } = event.target;
        setPermission({
            ...permission,
            [name]: checked,
        });
    };

    const allowPermission = async (id) => {
        await axios.post(`${process.env.REACT_APP_BASE_URL}permission?user=${id}`, { permission: JSON.stringify(permission) })
            .then((response) => {
                navigate('/employee');
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async function getRole() {
        await axios.get(`${process.env.REACT_APP_BASE_URL}role`)
            .then((response) => {
                setAllRoel(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handlePermissionChange = (event) => {
        const { name, checked } = event.target;
        setState({
            ...state,
            [name]: checked,
        });
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        if (checked) {
            setCheckboxValues([...checkboxValues, name]);
        } else {
            setCheckboxValues(checkboxValues.filter((value) => value !== name));
        }
    };

    const [initialValues, setInitialValues] = useState({
        fullname: "",
        email: "",
        password: "",
        role: "",
        permission: "",
        user_image: "",
        mobile_no: "",
        position: "",
        mac_address: "",
        address: "",
        status: false,
    });

    const validationSchema = yup.object({
        fullname: yup.string().required("Fullname is required."),
        email: yup.string()
            .required("Email is required.")
            .matches(
                /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
                "Please enter valid email address"
            ),
        role: yup.string().required("Role is required."),
        // mac_address: yup.string().required("Mac Address is required."),
        password: yup.string().nullable(true),
    });

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        setFieldValue
    } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
            setPending(true)
            values.permission = JSON.stringify(state);
            await axios({
                method: 'post',
                url: params.id ? `${process.env.REACT_APP_BASE_URL}add-user?user=${params.id}` : `${process.env.REACT_APP_BASE_URL}add-user`,
                data: values,
                headers: { 'Content-Type': 'multipart/form-data' }
            })
                .then((response) => {
                    setPending(false);
                    navigate('/employee');
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    });

    const fileChangeHandler = (event) => {
        setFieldValue("user_image", event.target.files[0]);
        if (event.target.files && event.target.files.length) {
            setPreImage(URL.createObjectURL(event.target.files[0]));
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <section className="content">
                        <form onSubmit={handleSubmit} autoComplete="off" encType='multipart/form-data'>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="user-image">
                                        <div className="user-preview-image" style={(params.id && !preImage) ? { backgroundColor: "#010226" } : (!preImage) ? { backgroundColor: "transparent" } : { backgroundColor: "transparent" }}>
                                            {values.user_image ? (
                                                <img src={preImage} alt="Default Images" />
                                            ) : (
                                                <div className='user-text-logo' style={(!preImage && !params.id) ? { fontSize: "unset" } : { fontSize: "75px" }}>{params ? values.fullname.charAt(0) : <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" stroke-width="0.1" stroke="#111111" fill="#fff" stroke-linecap="round" stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                                    <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                                                    <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855" />
                                                </svg>}</div>
                                            )}
                                        </div>
                                        <label htmlFor="user_image" className="">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_80_7131)">
                                                    <path d="M4 19.9998H8L18.5 9.49981C18.7626 9.23717 18.971 8.92537 19.1131 8.58221C19.2553 8.23905 19.3284 7.87125 19.3284 7.49981C19.3284 7.12838 19.2553 6.76058 19.1131 6.41742C18.971 6.07426 18.7626 5.76246 18.5 5.49981C18.2374 5.23717 17.9256 5.02883 17.5824 4.88669C17.2392 4.74455 16.8714 4.67139 16.5 4.67139C16.1286 4.67139 15.7608 4.74455 15.4176 4.88669C15.0744 5.02883 14.7626 5.23717 14.5 5.49981L4 15.9998V19.9998Z" stroke="#010226" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M13.5 6.5L17.5 10.5" stroke="#010226" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M16 19H22" stroke="#010226" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_80_7131">
                                                        <rect width="24" height="24" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            &nbsp;&nbsp; Upload Employee Images
                                            <input type="file" id="user_image" className="d-none" accept="image/png, image/jpeg" name="logo_file" onChange={fileChangeHandler} />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group mt-0">
                                        <label>
                                            Mac Address
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Enter Mac Address"
                                            name="mac_address"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.mac_address}
                                        />
                                        {errors.mac_address && touched.mac_address ? (
                                            <span className="required-lable">{errors.mac_address} </span>
                                        ) : null}
                                    </div>
                                    <div className="form-group m-32">
                                        <label>
                                            Full name<span className="required-lable">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Enter name"
                                            name="fullname"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.fullname}
                                        />
                                        {errors.fullname && touched.fullname ? (
                                            <span className="required-lable">{errors.fullname} </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group mt-0">
                                        <label>
                                            Email<span className="required-lable">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Enter email"
                                            name="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                        />
                                        {errors.email && touched.email ? (
                                            <span className="required-lable">{errors.email} </span>
                                        ) : null}
                                    </div>
                                    <div className="form-group mt-0">
                                        <label>
                                            Password<span className="required-lable">*</span>
                                        </label>
                                        <input
                                            type="password"
                                            placeholder="Enter password"
                                            name="password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password}
                                        />
                                        {errors.password && touched.password ? (
                                            <span className="required-lable">{errors.password} </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group mt-0">
                                        <label>
                                            Role<span className="required-lable">*</span>
                                        </label>
                                        <select onChange={(role) => setFieldValue('role', role.target.value)} name="role" onBlur={handleBlur}>
                                            <option value={""}>Select role</option>
                                            {
                                                allRoel.map((roleval, rolekey) => {
                                                    return <option key={rolekey} selected={values.role == roleval._id ? true : false} value={roleval._id}>{roleval.role}</option>
                                                })
                                            }
                                        </select>
                                        {errors.role && touched.role ? (
                                            <span className="required-lable">{errors.role} </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mt-0">
                                        <label>
                                            Mobile No
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Enter Mobile"
                                            name="mobile_no"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.mobile_no}
                                        />
                                        {errors.mobile_no && touched.mobile_no ? (
                                            <span className="required-lable">{errors.mobile_no} </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mt-0">
                                        <label>
                                            Position
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Enter Position"
                                            name="position"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.position}
                                        />
                                        {errors.position && touched.position ? (
                                            <span className="required-lable">{errors.position} </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group mt-0">
                                        <label>
                                            Address
                                        </label>
                                        <textarea
                                            type="text"
                                            placeholder="Enter Address"
                                            name="address"
                                            onChange={(add) => setFieldValue('address', add.target.value)}
                                            onBlur={handleBlur}
                                            value={values.address}
                                            rows={5}
                                        />
                                        {errors.address && touched.address ? (
                                            <span className="required-lable">{errors.address} </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group m-0">
                                                <label>Active Satus</label>
                                                <div class="button-cover">
                                                    <div class="button r" id="button-1">
                                                        <input type="checkbox" class="checkbox" name="status" checked={values.status} onChange={(status) => setFieldValue('status', status.target.checked)} />
                                                        <div class="knobs"></div>
                                                        <div class="layer"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="add-submit mt-0">
                                                <button type="submit" className="theme-btn-primary" disabled={IsPending ? true : false}>{IsPending ? 'Loding...' : 'Submit'}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
                <div className="col-md-6">
                    <section className="content">
                        <p className='permission-title'>Application Permission</p>
                        <div className='permission-box'>
                            <input type="checkbox" id="app_r" name='app_r' checked={permission.app_r} onChange={UserPermission} />
                            <label for="app_r">Application List</label>
                            <input type="checkbox" id="app_c" name='app_c' checked={permission.app_c} onChange={UserPermission} />
                            <label for="app_c">Create App</label>
                            <input type="checkbox" id="app_e" name='app_e' checked={permission.app_e} onChange={UserPermission} />
                            <label for="app_e">Edit App</label>
                            <input type="checkbox" id="app_d" name='app_d' checked={permission.app_d} onChange={UserPermission} />
                            <label for="app_d">Delete App</label>
                        </div>
                        <p className='permission-title'>Dashboard Permission</p>
                        <div className='permission-box mb-0'>
                            <input type="checkbox" id="home" name='home' checked={permission.home} onChange={UserPermission} />
                            <label for="home">Dashboard</label>
                        </div>
                        {params.id && <div className="add-submit mt-3">
                            <button type="submit" className="theme-btn-primary" onClick={() => allowPermission(params.id)} disabled={IsPending ? true : false}>{IsPending ? 'Loding...' : 'Submit'}</button>
                        </div>}
                    </section>
                </div>
            </div>
        </>
    )
}

export default AddUser;