import { useEffect, useState } from "react";
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { NavDropdown, Tab, Tabs } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './profile.css';

const Profile = () => {
    const [IsPending, setPending] = useState(false);
    const [IsPendingBtn, setIsPendingBtn] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);
    const search = useLocation().search;
    const params = new URLSearchParams(search);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [isLoding, setIsLoding] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataCount, setDataCount] = useState(0);
    const [attendanceData, setAttendanceData] = useState([]);
    const [actionMenu, setActionMenu] = useState({ id: null, status: 'd-none' });
    const [show, setShow] = useState(false);
    const [showTask, setShowTask] = useState(false);
    const [tasknotes, setTaskNotes] = useState({ todo: "", checkIn: "" });

    let CurrentDate = new Date();
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let todayDate = CurrentDate.getDate() + " " + months[CurrentDate.getMonth()] + " " + CurrentDate.getFullYear();

    useEffect(() => {
        getAllAttendance(currentPage);
    }, [currentPage]);

    async function getAllAttendance(page) {
        !isLoding && setIsLoding(true);
        let AttendanceUrl = (sDate && eDate)
            ? `${process.env.REACT_APP_BASE_URL}all-attendance?session=${params.get("session")}&sDate=${sDate}&eDate=${eDate}&pageCount=${page}`
            : `${process.env.REACT_APP_BASE_URL}all-attendance?session=${params.get("session")}&pageCount=${page}`
        await axios.get(AttendanceUrl, {
            headers: {
                'x-access-token': localStorage.getItem('session_id')
            }
        }).then((response) => {
            if (response.status === 200 && response.data.attendance) {
                let AttendanceDataCopy = response.data.attendance;
                setAttendanceData(AttendanceDataCopy);
                setDataCount(response.data.data)
                setIsLoding(false);
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const notify = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    const errorNotify = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: "colored",
    });

    let sDate, eDate;
    const SerachDate = async () => {
        sDate = startDate;
        eDate = endDate;
        getAllAttendance()
    }

    const CheckIn = async () => {
        setPending(true)
        let checkInJson = { "date": new Date(), "check_in": new Date(), "user": user._id };
        await axios({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}attendance`,
            data: checkInJson,
        })
            .then((response) => {
                setPending(false)
                if (response.status === 200 && response.data.message) {
                    notify(response.data.message);
                }
                if (response.status === 200 && response.data.data) {
                    getAllAttendance();
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    const checkOut = async () => {
        setIsPendingBtn(true)
        if (tasknotes !== "") {
            let checkOutJson = { "date": new Date(), "check_out": new Date(), "user": user._id, "todo": tasknotes };
            await axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}attendance`,
                data: checkOutJson,
            })
                .then((response) => {
                    setIsPendingBtn(false)
                    setShow(false);
                    getAllAttendance();
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            errorNotify("Notes should be written down.")
        }
    }

    const showTaskNotes = async () => {
        setPending(true)
        setTaskNotes("");
        await axios.get(`${process.env.REACT_APP_BASE_URL}all-attendance?session=${params.get("session")}&checkindate=${new Date()}`, {
            headers: {
                'x-access-token': localStorage.getItem('session_id')
            }
        })
            .then((result) => {
                setPending(false)
                let newData = { ...result.data }
                if (!newData.attendance) {
                    errorNotify("You're not a check-in employee.")
                } else if (newData.attendance.check_out) {
                    errorNotify("You're already checked out.")
                } else {
                    setShow(true);
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const ShowActions = (ActionId) => {
        if (actionMenu.status === 'd-none') {
            setActionMenu({ id: ActionId, status: 'd-block' });
        } else {
            setActionMenu({ id: ActionId, status: 'd-none' });
            if (ActionId !== actionMenu.id) {
                setActionMenu({ id: ActionId, status: 'd-block' })
            }
        }
    }

    const showMyTask = async (id) => {
        await axios.get(`${process.env.REACT_APP_BASE_URL}all-attendance?my_task=${id}`, {
            headers: {
                'x-access-token': localStorage.getItem('session_id')
            }
        }).then((response) => {
            setActionMenu({ id: id, status: 'd-none' })
            setTaskNotes({ todo: response.data.attendance.todo });
            setShowTask(true);
        }).catch((err) => {
            console.log(err);
        });
    }

    return (
        <>
            <ToastContainer />
            <section className="content user-profile">
                <Tabs defaultActiveKey="profile_user_info">
                    <Tab eventKey="profile_user_info" title="Personals Information">
                        <div className="profile-tab">
                            <p className="profile-head">Personals Information</p>
                            <form method="post">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="profile-info">
                                            <div className="profile-user-avatar">
                                                {!user.user_image ? <svg width="60" height="60" className="pre-image" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_238_12)">
                                                        <path d="M8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7Z" stroke="#dadada" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M6 21V19C6 17.9391 6.42143 16.9217 7.17157 16.1716C7.92172 15.4214 8.93913 15 10 15H14C15.0609 15 16.0783 15.4214 16.8284 16.1716C17.5786 16.9217 18 17.9391 18 19V21" stroke="#dadada" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_238_12">
                                                            <rect width="24" height="24" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg> : <img src={`${process.env.REACT_APP_BASE_URL}uploads/userimages/${user.user_image}`} alt="Default Images" />}
                                            </div>
                                            <div className="profile-user-info">
                                                <p>{user.fullname}</p>
                                                <p className="profile-mail">{user.email}</p>
                                                <sapn className="user-position">{user.role.role === "admin" ? user.role.role : user.position}</sapn>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Full Name</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Full Name"
                                                name="fullname"
                                                value={user.fullname}
                                                disabled={user.role.role === "admin" ? false : true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Email"
                                                name="email"
                                                value={user.email}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mt-0">
                                            <label>Mobile No</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Mobile No"
                                                name="mobile_no"
                                                value={user.mobile_no}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mt-0">
                                            <label>Role</label>
                                            <input
                                                type="text"
                                                value={user.role.role}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Tab>
                    {user.role.role !== "admin" &&
                        <Tab eventKey="profile_user_attendances" title="Attendances">
                            <div className="profile-tab">
                                <div className="attendance-head">
                                    <h4 className="current-time">Timesheet {todayDate}</h4>
                                    <div className="search-date mt-3 mt-xl-0">
                                        <DatePicker
                                            showIcon
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            selectsStart
                                            startDate={startDate}
                                            endDate={endDate}
                                            placeholderText="From Date"
                                        />&nbsp;&nbsp;&nbsp;
                                        <DatePicker
                                            showIcon
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}
                                            minDate={startDate}
                                            placeholderText="To Date"
                                        />&nbsp;&nbsp;&nbsp;
                                        <button className="theme-btn-primary py-0" onClick={SerachDate}>Go</button>
                                    </div>
                                    {<div className="check-in-out-btn mt-3 mt-xl-0">
                                        <button className="check-in-btn" onClick={CheckIn} disabled={IsPending ? true : false}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_305_1232)">
                                                    <path d="M3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12 12L9 10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12 7V12" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_305_1232">
                                                        <rect width="24" height="24" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>&nbsp; {IsPending ? 'Loding...' : 'Check In Time'}
                                        </button>
                                        <button className="check-out-btn" onClick={showTaskNotes} disabled={IsPending ? true : false}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_305_1232)">
                                                    <path d="M3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12 12L9 10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12 7V12" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_305_1232">
                                                        <rect width="24" height="24" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>&nbsp; {IsPending ? 'Loding...' : 'Check Out Time'}
                                        </button>
                                    </div>}
                                </div>
                                <div>
                                    <table className='table-list'>
                                        <thead>
                                            <tr>
                                                <th className="attend-filed">Date</th>
                                                <th className="attend-filed">Check In</th>
                                                <th className="attend-filed">Check Out</th>
                                                <th className="attend-filed">Hours</th>
                                                <th>To Do</th>
                                                <th className='table-action'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!isLoding ? attendanceData.length ? attendanceData.map((attendval, attendkey) => {
                                                var checkIn = new Date(attendval.check_in);
                                                var checkOut = new Date(attendval.check_out);
                                                var date = new Date(attendval.date);
                                                return (
                                                    <tr key={attendkey}>
                                                        <td>{date.toDateString()}</td>
                                                        <td>{checkIn.toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' })}</td>
                                                        <td>{attendval.check_out ? checkOut.toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' }) : "--"}</td>
                                                        <td>{attendval.total_hours ? attendval.total_hours : '--'}</td>
                                                        <td>{attendval.todo ? attendval.todo.substr(0, 200) : '--'}</td>
                                                        <td className='text-center'>
                                                            <NavDropdown title="Action">
                                                                <NavDropdown.Item>
                                                                    {(attendval.check_out) ?
                                                                        <div className='action-btn' onClick={() => showMyTask(attendval._id)}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="#575A74" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                                <path d="M5 3m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z"></path>
                                                                                <path d="M9 7l6 0"></path>
                                                                                <path d="M9 11l6 0"></path>
                                                                                <path d="M9 15l4 0"></path>
                                                                            </svg>
                                                                            <div className='action-text'>
                                                                                Task
                                                                            </div>
                                                                        </div> :
                                                                        <div className='action-btn'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#575A74" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                                <path d="M19.875 6.27c.7 .398 1.13 1.143 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z"></path>
                                                                                <path d="M12 16v.01"></path>
                                                                                <path d="M12 13a2 2 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483"></path>
                                                                            </svg>
                                                                            <div className='action-text'>
                                                                                Check out first.
                                                                            </div>
                                                                        </div>}
                                                                </NavDropdown.Item>
                                                            </NavDropdown>
                                                        </td>
                                                    </tr>
                                                )
                                            }) : <tr>
                                                <td colSpan={6} className='text-center theme-text-danager'>Not Found</td>
                                            </tr> : <tr>
                                                <td colSpan={6} className='text-center theme-text-danager position-relative'><div class="lds-ring"><div></div><div></div><div></div><div></div></div></td>
                                            </tr>}
                                        </tbody>
                                    </table>
                                </div>
                                {dataCount > 10 && <div className='footer-pagination'>
                                    <ul className='paginations d-flex justify-content-end gap-1'>
                                        {
                                            Array.apply(null, Array(Math.ceil(dataCount / 10))).map((val, key) => {
                                                return <li key={key} className={`pages-item ${currentPage === (key + 1) ? 'active' : ''}`} onClick={() => handlePageChange(key + 1)}>{key + 1}</li>
                                            })
                                        }
                                    </ul>
                                </div>}
                            </div>
                        </Tab>}
                </Tabs>
            </section>

            <Modal
                show={show}
                size="lg"
                onHide={() => setShow(false)}
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Task Notes
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form method="post">
                        <div className="form-group m-0">
                            <textarea
                                placeholder="Task Note..."
                                name="app_note"
                                rows={15}
                                onChange={(task) => setTaskNotes(task.target.value)}
                                value={tasknotes.todo}
                            />
                        </div>
                    </form>
                </Modal.Body>
                {/* {(tasknotes.todo === "") && */}
                <Modal.Footer>
                    <button className="theme-btn-primary" onClick={() => checkOut()} disabled={IsPendingBtn ? true : false} >{IsPendingBtn ? 'Loding...' : 'Check Out'}</button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showTask}
                size="lg"
                onHide={() => setShowTask(false)}
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Task Notes
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group m-0">
                        <textarea
                            rows={15}
                            value={tasknotes.todo}
                            disabled
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Profile;