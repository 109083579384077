import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { FaCirclePlus } from "react-icons/fa6";
import { Table } from 'react-bootstrap';
import axios from 'axios';

function MyRole() {
    const [roledata, setRoleData] = useState([]);

    useEffect(() => {
        getAllRole()
    }, []);

    async function getAllRole() {
        axios.get(`${process.env.REACT_APP_BASE_URL}my-role`)
            .then((response) => {
                setRoleData(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    return (
        <>
            <div className='content-header'>
                <h1>My Role</h1>
                <Link className='btn btn-secondary' to={'/add-role'}><FaCirclePlus color='#fff' size={18} />&nbsp;&nbsp;Add Role</Link>
            </div>
            <section className='content my-category'>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Role</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            roledata.map((roleval, rolekey) => {
                                return (
                                    <tr key={rolekey}>
                                        <td>{roleval.role}</td>
                                        <td>{roleval.status === 1 ? <button className='btn btn-success'>Enabled</button> : <button className='btn btn-danger'>Disabled</button>}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </section>
        </>
    )
}

export default MyRole