import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';


function MyRole() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let user = useSelector((state) => state.user.user);
    const [usersdata, setUserData] = useState([]);
    const [isLoding, setIsLoding] = useState(true);
    const [checkboxValues, setCheckboxValues] = useState([]);
    const [perId, setPerId] = useState('');
    const [actionMenu, setActionMenu] = useState({ id: null, status: 'd-none' });
    const [serachRes, setserachRes] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [dataCount, setDataCount] = useState(0);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [permission, setPermission] = useState({});

    useEffect(() => {
        if(user.role.role === "admin"){
            getAllUsers();
        } else {
            dispatch({ type: 'LOGOUT', payload: null });
            localStorage.removeItem('session_id');
            navigate("/");
        }        
    }, [serachRes]);

    const notify = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    async function getAllUsers() {
        !isLoding && setIsLoding(true);
        try {
            let UserUrl = (serachRes || dataCount) ? `${process.env.REACT_APP_BASE_URL}my-user?search=${serachRes}&pageCount=${dataCount}` : `${process.env.REACT_APP_BASE_URL}my-user/`
            const user = await axios.get(UserUrl);
            setUserData(user.data.users);
            setIsLoding(false);
        } catch (error) {
            console.error(error);
        }
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const UserPermission = (event) => {
        const { name, checked } = event.target;
        setPermission({
            ...permission,
            [name]: checked,
        });
    };

    const handleShow = async (id) => {
        await axios({
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}get-permission?user=${id}`,
        })
            .then((response) => {
                setActionMenu({ id: id, status: 'd-none' });
                setPerId(response.data.data._id);
                setCheckboxValues(response.data.data.permission);
                setPermission(JSON.parse(response.data.data.permission));
                setShow(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const allowPermission = async (id) => {
        await axios.post(`${process.env.REACT_APP_BASE_URL}/permission?user=${id}`, { permission: JSON.stringify(permission) })
            .then((response) => {
                handleClose();
                getAllUsers();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const attendance = (id) => {
        navigate(`/attendance?emp_id=${id}`);
    }

    const editUser = (id) => {
        navigate(`/add-employee/` + id)
    }

    const showActions = (ActionId) => {
        if (actionMenu.status === 'd-none') {
            setActionMenu({ id: ActionId, status: 'd-block' });
        } else {
            setActionMenu({ id: ActionId, status: 'd-none' });
            if (ActionId !== actionMenu.id) {
                setActionMenu({ id: ActionId, status: 'd-block' })
            }
        }
    }

    const deleteUser = async (id) => {
        await axios.get(`${process.env.REACT_APP_BASE_URL}delete-user?user=${id}`,)
            .then((res) => {
                if (res.status === 200) {
                    console.log(res.data.message);
                    notify(res.data.message)
                    getAllUsers();
                }
            }).catch((error) => {
                console.log(error.message);
            });
    }

    return (
        <>
        <ToastContainer />
            <section className='content'>
                <div className='content-header pb-4'>
                    <p className='text-theme'>All Employees</p>
                    <div className='content-header-right'>
                        <div className='content-header-search'>
                            <span className='search-icon'>
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11 19.5C15.4183 19.5 19 15.9183 19 11.5C19 7.08172 15.4183 3.5 11 3.5C6.58172 3.5 3 7.08172 3 11.5C3 15.9183 6.58172 19.5 11 19.5Z" stroke="#575A74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M20.9999 21.4999L16.6499 17.1499" stroke="#575A74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </span>
                            <input type="search" id="" className='' value={serachRes} placeholder='Employee name searching...' onChange={(search) => setserachRes(search.target.value)} />
                        </div>
                        <Link className='content-header-btn theme-primary' to={'/add-employee'} key="add-user-link">
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_86_9054)">
                                    <path d="M8 2.5C12.8 2.5 14 3.7 14 8.5C14 13.3 12.8 14.5 8 14.5C3.2 14.5 2 13.3 2 8.5C2 3.7 3.2 2.5 8 2.5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M10 8.5H6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8 6.5V10.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_86_9054">
                                        <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                                    </clipPath>
                                </defs>
                            </svg>
                            &nbsp;&nbsp;Add Employee
                        </Link>
                    </div>
                </div>
                <div className='row'>
                    {/* {!isLoding ? usersdata.length ? usersdata.map((userval, userkey) => {})} */}
                    {!isLoding ? usersdata.length ? usersdata.map((userval, userkey) => {
                        return <div className='col-md-3' key={userkey}>
                            <div className='employee-box'>
                                <div className='employee-image' style={userval.user_image ? {border: "unset"} : {border: "1px dashed #a3a3a3"}}>
                                    {userval.user_image
                                        ? <img src={`${process.env.REACT_APP_BASE_URL}uploads/userimages/${userval.user_image}`} alt={userval.fullname} />
                                        : <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" strokeWidth="0.1" stroke="#111111" fill="#fff" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                            <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                                            <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855" />
                                        </svg>
                                    }
                                </div>
                                <div className='employee-info'>
                                    <p className='employee-name'>{userval.fullname}</p>
                                    <p className='employee-position'>{userval.role.role === "admin" ? "admin" : userval.position}</p>
                                    <p className='employee-email'>{userval.email}</p>
                                    <hr />
                                    <div className='employee-action text-center'>
                                        <button className='theme-success-sm' title='Edit' onClick={() => editUser(userval._id)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" strokeWidth="2" stroke="white" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
                                                <path d="M6 21v-2a4 4 0 0 1 4 -4h3.5"></path>
                                                <path d="M18.42 15.61a2.1 2.1 0 0 1 2.97 2.97l-3.39 3.42h-3v-3l3.42 -3.39z"></path>
                                            </svg>
                                        </button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        {userval.role.role !== "admin" && 
                                        <><button className='theme-primary-sm' title='Attendance' onClick={() => attendance(userval._id)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" strokeWidth="2" stroke="white" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                                                <path d="M16 3l0 4"></path>
                                                <path d="M8 3l0 4"></path>
                                                <path d="M4 11l16 0"></path>
                                                <path d="M8 15h2v2h-2z"></path>
                                            </svg>
                                        </button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <button className='theme-danger-sm' title='Delete' onClick={() => deleteUser(userval._id)}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_146_525)">
                                                    <path d="M4 7H20" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10 11V17" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M14 11V17" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_146_525">
                                                        <rect width="24" height="24" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </button></>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }) : <div className='text-danger text-center'>Not Found.</div> : <div className='preloader'><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>}

                </div>
                {dataCount > 10 && <div className='footer-pagination'>
                    <ul className='paginations d-flex justify-content-end gap-1'>
                        {
                            Array.apply(null, Array(Math.ceil(dataCount / 10))).map((val, key) => {
                                return <li key={key} className={`pages-item ${currentPage === (key + 1) ? 'active' : ''}`} onClick={() => handlePageChange(key + 1)}>{key + 1}</li>
                            })
                        }
                    </ul>
                </div>}
            </section>

            {/* <Modal
                show={show} onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='permission-model'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Allow Permissions
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=''>
                        <p className='title'>Application Permission</p>
                        <div className='permission-box'>
                            <input type="checkbox" id="app_r" name='app_r' checked={permission.app_r} onChange={UserPermission} />
                            <label for="app_r">Application List</label>
                            <input type="checkbox" id="app_c" name='app_c' checked={permission.app_c} onChange={UserPermission} />
                            <label for="app_c">Create App</label>
                            <input type="checkbox" id="app_e" name='app_e' checked={permission.app_e} onChange={UserPermission} />
                            <label for="app_e">Edit App</label>
                            <input type="checkbox" id="app_d" name='app_d' checked={permission.app_d} onChange={UserPermission} />
                            <label for="app_d">Delete App</label>
                        </div>
                        <p className='title'>Dashboard Permission</p>
                        <div className='permission-box'>
                            <input type="checkbox" id="home" name='home' checked={permission.home} onChange={UserPermission} />
                            <label for="home">Dashboard</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='theme-btn-primary' onClick={() => allowPermission(perId)}>Submit</button>
                </Modal.Footer>
            </Modal> */}
        </>
    );
}

export default MyRole;
