import React from 'react'
import { Link } from "react-router-dom";

function PageNotFound() {
    return (
        <div class="section position-relative">
            <div className='page-not-found'>
                <img src='/icons/404-Error.svg' />
                <div class="page">Ooops!!! The page you are looking for is not found</div>
                <Link class="theme-btn-primary" to={'/home'} >Back to Home</Link>
            </div>
        </div>
    )
}

export default PageNotFound