import { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import axios from 'axios';
import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import weekends from "react-multi-date-picker/plugins/highlight_weekends"
import "./setting.css";

function Settings() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [role, setRole] = useState([]);
    const [value, setValue] = useState([]);
    const user = useSelector((state) => state.user.user);
    const [pendingRole, setPendingRole] = useState(false);
    const [pendingLeave, setPendingLeave] = useState(false);

    useEffect(() => {
        if (user.role.role === "admin") {
            getRole();
            getLeave();
        } else {
            dispatch({ type: 'LOGOUT', payload: null });
            localStorage.removeItem('session_id');
            navigate("/");
        }
    }, []);

    const notify = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    const getRole = async () => {
        await axios.get(`${process.env.REACT_APP_BASE_URL}my-role`, {
            headers: {
                'x-access-token': localStorage.getItem('session_id')
            }
        })
            .then(({ data }) => {
                setRole(data.data);
            })
            .catch((error) => {
                console.log(error);
            })
    };

    const getLeave = async () => {
        await axios.get(`${process.env.REACT_APP_BASE_URL}leave`, {
            headers: {
                'x-access-token': localStorage.getItem('session_id')
            }
        })
            .then(({ data }) => {
                setValue(data.data.leave);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const [initialValues, setInitialValues] = useState({
        role: ""
    });

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
    } = useFormik({
        initialValues,
        enableReinitialize: true,
        onSubmit: async (values) => {
            setPendingRole(true);
            await axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/add-role`,
                data: values,
            })
                .then((response) => {
                    getRole();
                    notify("A new role has been added.");
                    setPendingRole(false);
                })
                .catch((error) => {
                    setPendingRole(false);
                    console.log(error);
                });
        },
    });

    const leavesetting = async () => {
        setPendingLeave(true);
        let newLeaveDate = [...value];
        await axios({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}add-leave?id=${"653ce951a0a1c7888b73248e"}`,
            data: { "leave": newLeaveDate },
        })
            .then((response) => {
                notify("A new leave has been added.")
                getLeave();
                setPendingLeave(false);
            })
            .catch((error) => {
                console.log(error);
                setPendingLeave(false);
            });
    }

    const deleteRole = async (id) => {
        await axios.get(`${process.env.REACT_APP_BASE_URL}delete-role?role=${id}`, {
            headers: {
                'x-access-token': localStorage.getItem('session_id')
            }
        })
            .then((res) => {
                if (res.status === 200) {
                    getRole();
                }
            }).catch((error) => {
                console.log(error.message);
            });
    }

    return (
        <>
            <ToastContainer />
            <div className="row">
                <div className="col-md-4 col-12">
                    <section className="content settings">
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3 className="text-center mb-24">Roles Settings</h3>
                                </div>
                                <div className="col-md-12">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Role</th>
                                                <th>Assigned Role Count</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                role.map((val, key) => {
                                                    return <tr key={key}>
                                                        <td>{key + 1}</td>
                                                        <td style={{ textTransform: "capitalize" }}>{val.role}</td>
                                                        <td className="text-center">{0}</td>
                                                        <td className="text-center">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {deleteRole(val._id)}}>
                                                                <g clip-path="url(#clip0_146_525)">
                                                                    <path d="M4 7H20" stroke="#FF5636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M10 11V17" stroke="#FF5636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M14 11V17" stroke="#FF5636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7" stroke="#FF5636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7" stroke="#FF5636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_146_525">
                                                                        <rect width="24" height="24" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            {/* &nbsp;&nbsp;|&nbsp;&nbsp; */}
                                                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#359742" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                                                                <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"></path>
                                                            </svg> */}
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-12">
                                    <lable> Add New Role</lable>
                                    <div className="form-group mt-0">
                                        <input
                                            type="text"
                                            placeholder="Enter Role"
                                            name="role"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.role}
                                        />
                                        {errors.role && touched.role ? (
                                            <span className="required-lable">{errors.role} </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="add-submit mt-0">
                                    <button type="submit" className="theme-btn-primary" disabled={pendingRole ? true : false}>{pendingRole ? "Loding..." : "Submit"}</button>
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
                <div className="col-md-4 col-12">
                    <section className="content settings">
                        <form autoComplete="off">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3 className="text-center mb-24">Leave Settings</h3>
                                </div>
                                <div className="col-md-12">
                                    <Calendar
                                        multiple
                                        value={value}
                                        onChange={setValue}
                                        plugins={[
                                            <DatePanel sort="date" />,
                                            weekends([0])
                                        ]}
                                    />
                                </div>
                            </div>
                        </form>
                        <div className="add-submit text-center mt-4">
                            <button className="theme-btn-primary" onClick={leavesetting} disabled={pendingLeave ? true : false}>{pendingLeave ? "Loding..." : "Submit"}</button>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default Settings;