import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from "./sidebar";
import Navbar from "./navbar";

function Intro({ children }) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    globalUser();
  }, []);

  async function globalUser() {
    await axios.get(process.env.REACT_APP_BASE_URL + 'dashboard', {
      headers: {
        'x-access-token': localStorage.getItem('session_id')
      }
    })
      .then((res) => {
        dispatch({ type: 'LOGIN', payload: res.data.data });
      })
      .catch((error) => {
        if(error.response.status === 403) {
          navigate('/');
        }
      });
  }

  return (
    <>
      {user && <div className="wrapper">
        <Navbar />
        <Sidebar />
        <div className='content-wrapper'>
          {children}
        </div>
      </div>}
    </>
  );
}

export default Intro;